
import { api } from "@/api/api";
import { ApiGetGrantRateDto } from "@/api/generated/Api";
import TrainingGrantsModal from "@/components/administration/training-grants/TrainingGrantsModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "TrainingGrantsPage",
  components: {
    BaseTableFiltered,
    BaseModal,
    TrainingGrantsModal,
    BaseLayout,
  },
  setup() {
    const trainingGrants = ref<ApiGetGrantRateDto[]>();
    const modalData = ref(getInitialModalData());
    const search = ref("");

    const openAddGrant = useOpenModal(ModalType.Add, "", modalData);
    const openEditGrant = useOpenModal(ModalType.Edit, "", modalData);
    const openDisplayGrant = useOpenModal(ModalType.Display, "", modalData);
    const closeModal = (modalData: ModalBaseData) => {
      modalData.showModal = false;
      getTrainingGrants();
    };

    const getTrainingGrants = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        trainingGrants.value = (await api.economy.getGrantRates()).data;
      });
    };

    onMounted(async () => await getTrainingGrants());

    return {
      trainingGrants,
      modalData,
      headers,
      search,
      openEditGrant,
      openDisplayGrant,
      closeModal,
      formatDateShort,
      openAddGrant,
    };
  },
});

const headers = [
  { text: "År", value: "year" },
  { text: "Handlinger", value: "actions" },
  { text: "Opplæringstilskudd", value: "teachingGrantAmount" },
  { text: "Ekstra Faglige kurs", value: "extraProfessionalCourseAmount" },
  { text: "Versjon", value: "version" },
  { text: "Oppdatert av", value: "updatedByUserFullName" },
  { text: "Oppdatert når", value: "updated" },
];
