var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "År"
    },
    model: {
      value: _vm.selectedYear,
      callback: function callback($$v) {
        _vm.selectedYear = $$v;
      },
      expression: "selectedYear"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Beløp opplæringstilskudd",
      "rules": _vm.validateCourseHourInput({
        minVal: 0,
        maxVal: 100000
      })
    },
    model: {
      value: _vm.grant.teachingGrantAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.grant, "teachingGrantAmount", $$v);
      },
      expression: "grant.teachingGrantAmount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "readonly": _vm.readonly,
      "label": "Beløp ekstra faglige kurs",
      "rules": _vm.validateCourseHourInput({
        minVal: 0,
        maxVal: 100000
      })
    },
    model: {
      value: _vm.grant.extraProfessionalCourseAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.grant, "extraProfessionalCourseAmount", $$v);
      },
      expression: "grant.extraProfessionalCourseAmount"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }